
@import "ember-bootstrap/bootstrap";

html, body {
  background: #F7F7F7;
}

.container { max-width: 1250px !important; } 

.wrapper {
  padding: 50px 0px 50px 0px;
  min-height: 100vh;
  background: url('https://absolutecomedy.s3.amazonaws.com/assets/images/banner-bg.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}


.btn.btn-info {
  min-width: 200px;
  font-weight: 600;
}

.card-header {
  background: #C2E97F;
  text-align: center !important;
  a {
    color: #000 !important;
    font-size: 16px;
    line-height: 16px;
    text-decoration: none !important;
  }
}

.btn-secondary:disabled {
  background: #cccccc;
  border-color: #cccccc;
  text-align: center !important;
  color: #000 !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 37px;
  opacity: 0.5;
}

.header {
  height: 85vh;
  background: url('https://absolutecomedy.s3.amazonaws.com/assets/images/banner-bg.jpg') no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.btn.btn-primary {
  background: #7C42CA;
  border-color: #7C42CA;
  color: #ffffff;
}

.btn.btn-primary:hover {
  background: #B8A4DF;
  border-color: #B8A4DF;
  color: #ffffff;
}

.btn.btn-primary:focus {
  background: #B8A4DF !important;
  border-color: #B8A4DF !important;
  color: #ffffff;
}

.btn.btn-primary:active {
  background: #B8A4DF !important;
  border-color: #B8A4DF !important;
  color: #ffffff;
}

.btn.btn-primary:disabled {
  background: #B8A4DF;
  border-color: #B8A4DF;
  color: #ffffff;
}

.main {
  margin-top: -65vh;
}

.error-message {
  color: red !important;
}

h5.fancy-font {
  text-align: center;
}

h1.title {
  color: #F7C92E !important;
  font-family: BadaBoom BB !important;
  font-size: 80px;
  line-height: 85px;
  text-shadow: 3px 3px #000;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

h3.title {
  color: #F7C92E !important;
  font-family: BadaBoom BB !important;
  font-size: 40px;
  line-height: 50px;
  text-shadow: 3px 3px #000;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 10px 0px;
}

h5.label {
  font-size: 16px; 
  line-height: 18px;
  margin: 10px 0px 10px 0px;
  font-weight: 700;
  color: #000;
}

h6.label {
  font-size: 16px; 
  line-height: 20px;
  font-weight: 700;
  color: #000;
  margin: 0px 0px 5px 0px;
}

@media only screen and (max-width: 768px) {
  h1.title {
    color: #F7C92E !important;
    font-family: BadaBoom BB !important;
    font-size: 40px;
    line-height: 45px;
    text-shadow: 2px 2px #000;
  }

  h3.title {
    color: #F7C92E !important;
    font-family: BadaBoom BB !important;
    font-size: 40px;
    line-height: 45px;
    text-shadow: 2px 2px #000;
  }

  .navbar-header {
    width: 100%;
  }
}




@font-face {
  font-family: BadaBoom BB;
  src: url('/fonts/BADABB__.TTF');
}

.carousel-inner {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.z-depth-1 {
  -webkit-box-shadow: 0px 0px 25px rgba(151, 160, 191, 0.3);
  -moz-box-shadow: 0px 0px 25px rgba(151, 160, 191, 0.3);
  box-shadow: 0px 0px 25px rgba(151, 160, 191, 0.3);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none !important;
}

.fixed-top {
  -webkit-box-shadow: 0px 0px 25px rgba(151, 160, 191, 0.3);
  -moz-box-shadow: 0px 0px 25px rgba(151, 160, 191, 0.3);
  box-shadow: 0px 0px 25px rgba(151, 160, 191, 0.3);
  z-index:999;
}

.card {
  margin-bottom: 20px;
}

nav.bg-light {
  min-height: 100px;
  background-color: transparent !important;
}

.navbar-brand {
  color: #000 !important;
  font-weight: 600;
  /*
  color: #000 !important;
  font-family: BadaBoom BB !important;
  */
}

.nav-item a {
  color: rgba(60, 39, 110, 0.5) !important;
  font-weight: 600;
}

.nav-item.active a {
  color: #3C276E !important;
  font-weight: 600;
}

.nav-item a:hover {
  color: #3C276E !important;
  font-weight: 600;
}

.dropdown-menu {
  .dropdown-item {
    color: rgba(60, 39, 110, 0.5) !important;
  }
  .dropdown-item.active {
    color: #3C276E !important;
    font-weight: 600;
    background: #ffffff !important;
  }
  .dropdown-item:hover {
    color: #3C276E !important;
    font-weight: 600;
    background: #ffffff !important;
  }
}

.btn.btn-default {
  background: #C2E97F;
  border-color: #C2E97F;
  color: #000;
}

.fc-time {
  display: none;
}

.fc-event {
  background: #7C43CA;
  border: 1px solid #7C43CA;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  padding: 3px;
  border-radius: 25px;
  cursor: pointer;
}

.fc-event.fc-not-start {
  color: transparent !important;
}

.fc-highlight {
  background-color: #DCE0E1 !important;
  opacity: 1;
}

.fc-content-skeleton {
  table {
    tbody {
      tr:first-child {
        td {
          padding-top: 20px !important;
        }
      }
      tr:last-child {
        td {
          padding-bottom: 20px !important;
        }
      }
    }
  }
}

.cal_tickets {
  background: #7C43CA !important;
  border: 1px solid #7C43CA !important;
  color: #ffffff !important;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  padding: 3px 0px 3px 0px;
  border-radius: 25px;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  cursor: pointer;
}

.cal_tickets span:hover {
  font-size: 0;
  line-height: 0;
}

.cal_tickets span:hover:before {
  color: #ffffff !important;
  text-align: center;
  font-size: 11px;
  line-height: 14px;
  content: "Buy Tickets";
}


.fc-day-grid-event .fc-content {
  white-space: normal; 
}

.cal_special {
  background: #7C43CA !important;
  border: 1px solid #7C43CA !important;
  color: #ffffff !important;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  padding: 3px 0px 3px 0px;
  border-radius: 25px;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  cursor: pointer;
}

.cal_special span:hover {
  font-size: 0;
  line-height: 0;
}

.cal_special span:hover:before {
  color: #000000 !important;
  text-align: center;
  font-size: 11px;
  line-height: 14px;
  content: "Buy Tickets";
}

.cal_lineup {
  background: #F7C92E !important;
  border: 1px solid #F7C92E !important;
  color: #000000 !important;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  padding: 3px 0px 3px 0px;
  border-radius: 25px;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  cursor: pointer;
}

.cal_holidays {
  background: red !important;
  border: 1px solid red !important;
  color: #fff !important;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  padding: 3px 0px 3px 0px;
  border-radius: 25px;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  cursor: pointer;
}

.cal_expired {
  background: #D7D7D7 !important;
  border: 1px solid #D7D7D7 !important;
  color: #A0A0A0 !important;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  padding: 3px;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.fc-toolbar {
  h2 {
    font-size: 25px;
  }
}



.StripeElement {
  margin-bottom: 0px;
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  /* box-shadow: 0 1px 3px 0 #e6ebf1; */
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.cart {
  background: #DDDFE5;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  .table > tbody > tr > td {
    border-color: #B3B8C5;
  }
}

.table > tbody > tr:first-child > td {
  border: none;
}

.table > tbody > tr > td {
  border-color: #E8EBF2;
}

.StripeElement--focus {
  /* box-shadow: 0 1px 3px 0 #cfd7df; */
  border: 1px solid #CCCCCC;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.pre-footer {
  margin-top: 30px;
  background: #222738; 
  padding: 50px 0px 50px 0px;
  border-top: 15px solid #C2E97F;
}

.aside {
  background: #C2E97F !important;
  table {
    tr {
      td {
        border-color: rgba(0,0,0,0.1) !important;
      }
    }
  }
}


.footer {
  background: #222738;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

#myFooter {
  color: white;
  padding-top: 20px;
  margin-top: 0px;
  background: url('https://absolutecomedy.s3.amazonaws.com/assets/images/schedule-single-bg.png') no-repeat center center; 
  background-size: 100% 100%;
}

#map-container {
  height: 240px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}

#myFooter .row {
  margin: 0 85px;
}

#myFooter .footer-copyright {
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}

#myFooter .footer-copyright p {
  margin: 10px;
  color: #979AA0;
}

#myFooter .container {
  width: auto;
}

#myFooter ul {
  list-style-type: none;
  padding-left: 0;
  line-height: 1.7;
}

#myFooter h5 {
  font-size: 18px;
  color: white;
  font-weight: bold;
  margin-top: 30px;
}

#myFooter a {
  color: #979AA0;
  text-decoration: none;
}

#myFooter a:hover,
#myFooter a:focus {
  text-decoration: none;
  color: white;
}

#myFooter .social-networks {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 38px;
}

#myFooter .fa {
  font-size: 30px;
  margin-right: 15px;
  margin-left: 20px;
  background-color: white;
  color: #25282E;
  border-radius: 51%;
  padding: 10px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 31px;
  text-decoration: none;
  transition: color 0.2s;
}

#myFooter .fa-facebook:hover {
  color: #2b55ff;
}

#myFooter .fa-facebook:focus {
  color: #2b55ff;
}

#myFooter .fa-google-plus:hover {
  color: red;
}

#myFooter .fa-google-plus:focus {
  color: red;
}

#myFooter .fa-twitter:hover {
  color: #00aced;
}

#myFooter .fa-twitter:focus {
  color: #00aced;
}

@media screen and (max-width: 767px) {
  #myFooter {
      text-align: center;
  }
  #myFooter .row {
      margin: 0;
  }
}


.content{
 flex: 1 0 auto;
 -webkit-flex: 1 0 auto;
 min-height: 200px;
}

#myFooter{
 flex: 0 0 auto;
 -webkit-flex: 0 0 auto;
}


.u-float-right {
  float: right;
}

.u-flex-center {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
}

.subtle {
  color: #888;
  font-size: 12px;
}

.card-media {
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .12);
  height: 125px;
  margin-bottom: 25px;
  transition: all 300ms ease-out;
  width: 100%;
}

.card-media:hover {
  box-shadow: 0 5px 14px rgba(0, 0, 0, .2);
}

.card-media-object-container {
  background: none;
  float: left;
  height: 100%;
  width: 35%;
  position: relative;
}

.card-media-object {
  background-position: center center;
  background-size: cover;
  height: 100%;
}

.card-media-object:after {
  content: " ";
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 300ms ease-out;
  z-index: 10;
}

.card-media:hover .card-media-object:after {
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 52%, rgba(0,0,0,0.4) 100%);
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 52%,rgba(0,0,0,0.4) 100%);
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 52%,rgba(0,0,0,0.4) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
  opacity: 1;
}

.card-media-object-tag {
  background-color: #fff;
  border-radius: 2px;
  padding: 2px 7px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.card-media-object-social-list {
  bottom: 4px;
  left: 10px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 20;
}

.card-media-object-social-list li {
  border-radius: 50%;
  display: inline-block;
  height: 30px;
  margin-right: 6px;
  opacity: 0;
  overflow: hidden;
  transform: translateY(5px);
  transition: all 300ms ease-out;
  width: 30px;
}

.card-media:hover .card-media-object-social-list li {
  opacity: 1;
  transform: translateY(0);
}

.card-media-object-social-list li:nth-child(1) {
  transition-delay: 0;
}
.card-media-object-social-list li:nth-child(2) {
  transition-delay: 75ms;
}
.card-media-object-social-list li:nth-child(3) {
  transition-delay: 150ms;
}

.card-media-object-social-list-item-additional {
  border: 1px solid #fff;
  color: #fff;
  font-size: 12px;
  padding-top: 7px;
  text-align: center;
}

.card-media-body {
  background-color: #fff;
  float: left;
  height: 100%;
  padding: 12px 15px;
  position: relative;
  width: 65%;
}

.card-media-body-top {
  display: block;
}

.card-media-body-top-icons {
  margin-top: -2px;
  opacity: 0;
  transition: all 300ms ease-out;
  transform: translateY(-5px);
}

.card-media:hover .card-media-body-top-icons {
  opacity: 1;
  transform: translateY(0);
}

.card-media-body-top-icons > svg {
  cursor: pointer;
  margin-left: 10px;
  transition: all 300ms ease-out;
}

.card-media-body-top-icons > svg:hover {
  fill: #444;
}

.card-media-body-heading {
  display: block;
  margin-top: 10px;
}

.card-media-body-supporting-bottom {
  position: absolute;
  bottom: 10px;
  left: 0;
  opacity: 1;
  padding: 0 15px;
  transition: all 300ms ease-out;
  width: 100%;
}

.card-media:hover .card-media-body-supporting-bottom {
  opacity: 0;
  transform: translateY(-8px);
}

.card-media-body-supporting-bottom-text {
  display: inline-block;
}

.card-media-body-supporting-bottom-reveal {
  opacity: 0;
  transform: translateY(8px);
  transition: all 300ms ease-out;
}

.card-media:hover .card-media-body-supporting-bottom-reveal {
  opacity: 1;
  transform: translateY(0);
}

.card-media-link {
  color: #41C1F2;
  text-decoration: none;
}

.error {
  border: 1px solid red !important;
}

::-webkit-input-placeholder { /* Edge */
  color: #cccccc !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #cccccc !important;
}

::placeholder {
  color: #cccccc !important;
}